//colors
$primary: #ff661d;
$secondary: #d2d2d2;

//color variations
$primary-faded: #ff661cb0;

//pagination positions
$actual-page: 0;
$previous-page: +200%;
$next-page: -200%;
