@import '../components/core';

.app-title-bar {
  background-color: #fff;
  margin-top: 60px;
  position: relative;
  z-index: 1;

  &::before {    
    width: 100%;
    height: 5px;
    display: block;
    content: '';
    background: transparent linear-gradient(90deg, #FF661C 0%, #FF9D1DE2 24%, #FF392A 51%, #FF9C2A 77%, #FF392A 100%) 0% 0% no-repeat padding-box; 
  }

  &-content {
    min-height: 80px;
    box-sizing: border-box;
    background-repeat: no-repeat;
    padding: 1em 0.5em;
    background-color: #fff;
    background-position: right bottom;
    background-size: 110px;
    
    @media(min-width: $screen-tablet-min) {
      background-size: 176px;
    }

    &-buttons {
      // margin: $space-1 auto 0 !important;
      width: 100%;
            
      @media(min-width: $screen-desktop-min) {
        justify-content: space-between;
        width: initial;
        margin: initial;
      }

      &-wrapper {
        position: relative;
        z-index: 1;    
        
        &::before {
          width: 20px;
          height: 100%;
          content: "";
          position: absolute;
          left: -2px;
          top: 0; 
          z-index: 1;   
        }

        &::after {
          width: 12px;
          height: 100%;
          content: "";
          position: absolute;
          background: linear-gradient(90deg, #0000, #fff);
          right: -2px;
          top: 0;  
          z-index: 1;        
        }

        &-info {
          display: flex;
          overflow: auto;
          padding: 0 10px;
          box-sizing: border-box;  
          
          button {
            min-width: 90px;
  
            .MuiButton-label {
              display: contents;
            }
          }
        }
      }

      button {
        font-size: 12px;
        margin-right: $space-1;
        margin-bottom: $space-1;
        
        @media(min-width: $screen-tablet-min) {
          font-size: 14px;
        }
        
        &:last-child {
          margin-right: 0;
        }
      }
    }

    &-title {
      font-size: 1.35em;
      font-weight: 700;
      
      @media(min-width: $screen-tablet-min) {
        font-size: 1.65em;
      }
    }
  }
}