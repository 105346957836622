.loginGigya {
  display: flex;
  align-items: center;
  justify-content: center;
    
    .gigya-layout-row{      
      .gigya-composite-control.gigya-composite-control-textbox.email-wrapper.left.clear.reg-field.box-size-40.email-box.req-fields{
        width: 100% !important;
      }

      .gigya-composite-control.gigya-composite-control-header.small-header2 {
        padding-top: 20px !important;
      }

      .gigya-layout-cell{
        width: 100% !important;

        .Continue input {
          margin-right: 0 !important;
        }
      }
    }
}