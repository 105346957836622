@import '../components/core.scss';

.app-farm-form {
  padding-top: $space-2;
  
  .grid-item {
    padding: 0 $space-1;
  
    @media(max-width: $screen-tablet-max) {
      .MuiFormLabel-root:not(.Mui-focused) {
        font-size: 11px;
      }
    }

    @media(max-width: $screen-phone-max) {
      .MuiFormLabel-root:not(.Mui-focused) {
        font-size: 10px;
      }
    }
  }
}