$shadow: rgba(0, 0, 0, 0.75);
$white: white;
$orange: #ea5d0d;

.syncViewError {
  background: $white;
  color: $white;
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  height: 45px;
  width: 90px;
  border-radius: 50px; // 0 50% 50% 0;
  -webkit-box-shadow: 0px 2px 4px 0px $shadow;
  -moz-box-shadow: 0px 2px 4px 0px $shadow;
  box-shadow: 0px 2px 4px 0px $shadow;
}

.syncView {
  background: $white;
  color: $white;
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  height: 45px;
  width: 45px;
  border-radius: 50%; // 0 50% 50% 0;
  -webkit-box-shadow: 0px 2px 4px 0px $shadow;
  -moz-box-shadow: 0px 2px 4px 0px $shadow;
  box-shadow: 0px 2px 4px 0px $shadow;
}

.syncViewSuccess {
  background: $white;
  color: $orange;
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  height: 45px;
  width: 90px;
  border-radius: 50px; // 0 50% 50% 0;
  -webkit-box-shadow: 0px 2px 4px 0px $shadow;
  -moz-box-shadow: 0px 2px 4px 0px $shadow;
  box-shadow: 0px 2px 4px 0px $shadow;
}

.syncViewIcon {
  position: fixed;
  bottom: 22.5px;
  right: 22.5px;
}

.syncViewPushIcon {
  // position: fixed;
  // bottom: 22.5px;
  // right: 22.5px;
  animation: sync-push infinite 1s linear;
}

@keyframes sync-push {
  from {
    transform: translateY(3px);
  }
  to {
    transform: translateY(-3px);
  }
}
