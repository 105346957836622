.recharts-wrapper {
  margin: 0 auto;
}

.onlywhenprinting {
  visibility: hidden;
  position: fixed;
}

div.google-visualization-tooltip {
  color: rgb(109, 102, 102);
}

.MuiTableCell-root {
  border-bottom: none !important;
}

.MuiTableRow-root:not(:last-child) {
  border-bottom: 1px solid #dddddd !important;
  -webkit-print-color-adjust: exact;
}

.printableTableContainer tbody > tr:last-child {
  background-color: #ff8741;
  border: #eee !important;
  color: #eee !important;
  -webkit-print-color-adjust: exact;
}

.printableTableContainer td > td:last-child {
  color: white !important;
}

.printableTableContainer td {
  color: #585858 !important;
}

.printableTableContainer th {
  background-color: #f77327;
  color: #eee;
  -webkit-print-color-adjust: exact;
}

.progerarLogo {
  width: 100%;
  position: absolute;
}

.zoetisLogo {
  position: absolute;
  right: -340px;
  bottom: 0;
}

.fixedZoetisLogo {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 150px;
}

.logos {
  width: 100%;
  height: 500px;
  position: relative;
}

.titleBox {
  width: 100%;
  height: 300px;
  font-size: 60px;
  display: flex;
  flex-direction: column;
}

.middleContent {
  width: 100%;
  font-size: 30px;
  height: 650px;
}

@media print {
  .pagebreak {
    page-break-after: always;
    
    &:last-child {
      page-break-after: avoid;
    }
  }

  .pagenobreak {
    page-break-inside: avoid;
  }

  .app-wrapper-bg {
    background: none !important;
  }

  .hidewhenprinting {
    display: none !important;
  }

  .onlywhenprinting {
    visibility: visible;
    position: relative;
  }

  .MuiContainer-maxWidthLg {
    max-width: unset !important;
  }

  .mainContainerDiv {
    zoom: 0.96 !important;
  }

  .marginTop {
    padding-top: 60px;
  }

  @page {
    margin: 0;
  }

  .MuiTableCell-root {
    border-bottom: none !important;
  }

  .MuiTableRow-root:not(:last-child) {
    border-bottom: 0.5px solid #dddddd !important;
  }

  .printableTableContainer tbody > tr:last-child {
    background-color: #ff8841 !important;
    border: #eee !important;
    color: #eee !important;
  }

  .printableTableContainer td > td:last-child {
    color: white !important;
  }

  .printableTableContainer td {
    color: #585858 !important;
  }

  .printableTableContainer th {
    background-color: #f66b19;
    color: #eee;
  }
}

.span {
  position: absolute;
  margin-top: '30px' !important;
  bottom: 10;
  right: 10%;
  z-index: 999;
  padding: 2px 15px;
  border-right: 2px solid #f66b19;
  font-size: 12px;

  color: black;
}

.buttonIcon {
  width: 30px;
  height: 30px;
  position: relative;
  left: -13px;
  align-items: center;
  justify-content: center;
  display: flex;
  background: #ccc;
  border-radius: 100px;
}
