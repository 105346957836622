.card {
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  margin: 0 0.6rem 1.6rem 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 100%;
  box-shadow: 4px 6px 15px rgba(0, 0, 0, 0.4%);

  h2 {
    padding: 1rem;
    font-size: 1.25rem;
    color: #ea5d0d;
    font-weight: 500;
  }

  h5 {
    font-weight: 600;
    color: #ea5d0d;
  }

  p {
    font-weight: 400;
  }

  .card-header {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid #ea5d0d;
    background: #f8f8f8;
    position: relative;

    .checkbox{
      position: absolute;
    }

    .card-title {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      padding: 0.6rem;
      background: #f8f8f8;
      border-radius: 8px 8px 0 0;
      cursor: pointer;
      transition: ease all 0.2s;
  
      .title-link {
        text-decoration: underline;
      }
  
      &:hover {
        background: #f0f0f0;
  
        .title-link {
          text-decoration: underline;
          color: #ea5d0d;
        }
      }
    }
  
    .card-title-without-link {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      padding: 0.6rem;
      background: #f8f8f8;
      border-radius: 8px 8px 0 0;
      border-bottom: 1px solid #ea5d0d;
    }
  }


  .card-body {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 1rem;
    text-align: center;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0.5rem 0;
    }
  }

  .card-footer {
    display: flex;
    align-items: center;
    justify-content: right;
    width: 100%;
    padding: 0.6rem;
    background: #f8f8f8;
    border-radius: 0 0 8px 8px;
    border-top: 1px solid #ea5d0d;
    
    .manejos {
      display: flex;
      flex-wrap: wrap;
      
      > button {
        flex-grow: 1;
        
        &:hover{
          filter: brightness(0.94);
        }
      }

    }
  }
}
