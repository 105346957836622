@import '../components/core.scss';

$thWidth: 154px;

.top-table-pagination {
  margin: $space-2 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: 1px solid #f3f3f3;
  border-bottom: none;
  border-radius: $space-1 $space-1 0 0;
  box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.8);

  @media (max-width: $screen-tablet-min) {
    box-shadow: none;
    border-radius: 0;
    border: none;
  }
}

.app-responsive-table {
  margin: $space-2 0;
  border-radius: $space-1;
  overflow: hidden;
  font-size: 11px;

  @media (min-width: $screen-tablet-min) {
    font-size: inherit;
    box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.8);
  }

  thead {
    color: #000000de;
    font-weight: 500;
    background-color: #e3e3e3;

    th {
      line-height: $space-3;
      text-align: left;
      padding: $space-2;
      box-sizing: border-box;
      vertical-align: middle;
      font-weight: 500;

      .column-header {
        display: flex;
        align-items: center;
      }
    }
  }

  .image-center {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 35;
    right: 0;
    margin: auto;
  }

  tbody {
    tr {
      margin-bottom: $space-3;

      @media (min-width: $screen-tablet-min) {
        border-bottom: 1px solid #e3e3e3;
        margin-bottom: 0;
      }
    }

    td {
      padding: $space-1;
      word-break: break-word;

      @media (min-width: $screen-desktop-min) {
        padding: $space-2;
      }

      color: #000000de;

      &.td-float-buttons {
        justify-content: flex-end;

        @media (max-width: $screen-phone-max) {

          label .td-float-buttons-trigger,
          label input {
            display: none;
          }
        }

        @media (min-width: $screen-tablet-min) {
          // width: 180px;
          width: 246px;

          label {
            cursor: pointer;
            position: relative;
            margin-right: $space-1;

            input {
              display: none;

              &+div {
                position: absolute;
                right: 0;
                display: flex;
                opacity: 0;
                pointer-events: none;
                flex-wrap: nowrap;
                background: #fff;
                padding: 2px;
                box-shadow: 1px 1px 4px -1px rgba(0, 0, 0, 0.4);
                z-index: 1;
                transition: 0.2s all ease;
                transform: translateY(-10px);
                border-radius: 300px;

                button {
                  margin: 0 $space-1;
                  border-radius: 100px;

                  .MuiButton-label {
                    word-break: keep-all;
                    width: max-content;
                  }

                  &:hover {
                    filter: brightness(1.2);
                  }
                }
              }

              &:checked {
                &+div {
                  opacity: 1;
                  pointer-events: initial;
                  transform: translateY(0);
                }
              }
            }

            &:hover {
              &::before {
                width: 200px;
                height: 90px;
                content: '';
                position: absolute;
                top: 0;
                left: -160px;
              }

              input {
                &+div {
                  opacity: 1;
                  pointer-events: initial;
                  transform: translateY(0);
                }
              }
            }
          }
        }
      }

      &.td-btn,
      &.td-btn-arr,
      &.td-icon-btn {
        .td-btn-arr-icon {
          padding: 0;
          margin: 0 4px;

          @media (min-width: $screen-desktop-min) {
            margin: 0 2px;
          }
        }

        .td-btn-arr-btn {
          padding: 8px;
          margin: 0 2px;
          min-width: 60px;
          font-size: 11px;

          @media (min-width: $screen-tablet-min) {
            padding: 4px;
            margin: 0 2px;
            min-width: 36px;
            font-size: 11px;
          }

          @media (min-width: $screen-desktop-min) {
            padding: 2px;
            margin: 0 2px;
            min-width: 50px;
            font-size: 12px;
          }

          @media (min-width: $screen-monitor-min) {
            padding: 8px;
            margin: 0 2px;
            min-width: 64px;
            font-size: 14px;
          }
        }

        @media (max-width: $screen-phone-max) {
          box-shadow: none;
          justify-content: flex-end;
          display: flex;

          .tdBefore {
            background: none;
          }
        }
      }
    }
  }

  &.responsiveTable {
    width: 100%;

    td .tdBefore {
      display: none;
    }

    .collapse-icon {
      display: none;
    }

    // .collapse-link {
    //   display: none;
    // }

    @media only screen and (max-width: $screen-phone-max) {

      table,
      thead,
      tbody,
      th,
      td,
      tr {
        display: block;
      }

      thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }

      tbody tr {
        padding: 0.25em;
        margin-bottom: $space-1;

        td:not(:first-child) {
          display: none;
        }

        td:first-child {
          padding-right: 34px;

          &:not(.visible) {
            background: #eee;
            border-radius: 6px;
            box-shadow: 0 -1px 0 0px #e3e3e3, 1px 0 0 0px #e3e3e3,
              0 4px 4px -3px #999b;
            padding: $space-1 52px $space-1 $space-1;

            .collapse-icon svg {
              transform: rotate(180deg);
            }

            .tdBefore {
              display: none;
            }
          }
        }

        td.visible {
          display: flex;
        }
      }

      td.pivoted {
        border: none !important;
        position: relative;
        padding: $space-1 $space-1 $space-1 $thWidth;
        text-align: left !important;
        white-space: pre-wrap;
        overflow-wrap: break-word;
        overflow: hidden;
        min-height: 51px;
        line-height: 17px;
        display: flex;
        align-items: center;

        &:nth-child(even) {
          box-shadow: 0 -1px 0 0px #e3e3e3, 1px 0 0 0px #e3e3e3;
        }

        &:nth-child(odd) {
          box-shadow: 0 -1px 0 0px #e3e3e3, 1px 0 0 0px #e3e3e3;
        }

        &:first-child {
          border-radius: $space-1 $space-1 0 0;
        }

        &:last-child {
          box-shadow: 0 -1px 0 0px #e3e3e3, 1px 0 0 0px #e3e3e3,
            0 1px 0 0px #e3e3e3;
          border-radius: 0 0 $space-1 $space-1;
        }

        &.td-btn,
        &.td-btn-arr,
        &.td-icon-btn,
        &.td-float-buttons {
          flex-wrap: wrap;
          padding-left: $space-1 !important;
          background-color: #f8f8f8;

          .tdBefore {
            background: transparent;
          }
        }

        .collapse-link {
          display: flex;
          text-align: right;
        }

        .collapse-icon {
          width: 30px;
          height: 40px;
          position: absolute;
          right: 4px;
          background-color: #eee;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;

          svg {
            transition: 0.2s transform ease;
          }
        }
      }

      td {
        &:first-child .tdBefore {
          box-shadow: none;
          border-radius: $space-1 0 0 0;
        }

        &:last-child .tdBefore {
          border-radius: 0 0 0 $space-1;
        }

        .tdBefore {
          position: absolute;
          color: #000000de;
          font-weight: 500;
          background-color: #e3e3e3;
          top: 0;
          left: 0;
          box-sizing: border-box;
          width: calc(#{$thWidth} - #{$space-1});
          text-align: left !important;
          overflow: auto;
          padding: 8px 4px 8px 4px;
          height: 100%;
          box-shadow: 0 2px 0 -1px #fff inset;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}