@import '../components/core.scss';

.grid-merge {
  .grid-item {
    margin-bottom: $space-2;
  
    .MuiIconButton-root.plus-icon {
      padding: 0;
      display: contents;
    }
  }
}