@import 'base/_base';
@import 'components/_components';

@import 'pages/Breadcrumbs';
@import 'pages/MainContainer';
@import 'pages/Chart';
@import 'pages/ResponsiveTable';

@import 'pages/TitleBar';
@import 'pages/TopBar';
@import 'pages/Home';
@import 'pages/Batch';
@import 'pages/Merge';
@import 'pages/Corral';
@import 'pages/Farm';
@import 'pages/UserList';
@import 'pages/BullsList';
@import 'pages/ProtocolsList';
@import 'pages/D0';
@import 'pages/DN';
@import 'pages/DGFinal';
@import 'pages/DG';
@import 'pages/D0Resync';
@import 'pages/App';
@import 'pages/BullForm';
@import 'pages/BullFormForAll';
@import 'pages/Resync';
@import 'pages/IATF';
@import 'pages/UserForm';
@import 'pages/FarmForm';
@import 'pages/Login';
@import 'pages/ProtocolForm';
@import 'pages/BatchForm';
@import 'pages/Training';

main {
  max-width: 100%;
}

::-webkit-scrollbar {
  width: 10px;

  &-track {
    background: #f1f1f1;
  }
  &-thumb {
    background: rgb(173, 173, 173);
    &:hover {
      background: rgb(161, 161, 161);
    }
  }
}
