@import '../components/core';

$sidebar-width: 100%;

.app-header {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 6px rgba(0,0,0,.15);
  z-index: 2;

  &-fixed {
    position: fixed;
    top: 0;
    left: 0;

    &::before {
      width: 100%;
      height: 10px;
      background: transparent linear-gradient(90deg, #FF661C 0%, #FF9D1DE2 24%, #FF392A 51%, #FF9C2A 77%, #FF392A 100%) 0% 0% no-repeat padding-box;
      display: block;
      content: '';
    }
  }

  &-container {
    display: flex;
    padding: 0 24px;
    min-height: $space-7;
    align-items: center;    

    &-logo {
      width: 240px;
      display: flex;
      z-index: 1;

      @media(min-width: $screen-desktop-min) {
        padding-left: $space-3;
      }
    }

    &-menu {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    
      input {
        width: 30px;
        height: 3px;
        background-color: $primary-color;
        content: '';
        cursor: pointer;
        position: absolute;
        top: 36px;
        right: 16px;
        z-index: 1;
        appearance: none;

        @media(min-width: $screen-tablet-min) {
          display: none;
        }

        &::before,
        &::after {
          width: 100%;
          height: inherit;
          background-color: $primary-color;
          content: '';
          position: absolute;
          z-index: 1;
          transition: .2s transform ease;
        }

        &::before {  
          top: -360%;
        }
        &::after {  
          top: 360%;
        }

        &:checked {
          background: transparent;
          transform: rotatez(-94deg);

          &::before,
          &::after {
            background-color: #ccc;
            transform-origin: left bottom;
          }

          &::before {
            transform: rotatez(50deg);
          }

          &::after {
            transform: rotatez(-40deg);
          }

          + .app-header-container-menu-items {
            @media(max-width: $screen-tablet-max) {
              transform: translateX(0);
            
              + .app-header-container-menu-items-mask {
                width: 100vh;
                height: 100vh;
                background-color: #000;
                position: absolute;
                top: 0;
                left: $sidebar-width;
                padding: 0;
                opacity: .2;
                z-index: 1;
              }            
            }
          }
        }
      }

      &-items {
        &-mask {
          transition: .3s .2s opacity ease;
          opacity: 0;
        }

        @media(max-width: $screen-phone-max) {
          position: absolute;
          padding: $space-3;
          flex: initial;
          left: 0;
          top: 74px;
          transition: .3s transform ease;
          transform: translateX(2000px);
          min-height: calc(100vh - 74px);
          width: $sidebar-width;
          background-color: #fff;
          margin-top: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;          

          button {
            justify-content: flex-start;
            font-size: 18px;
          }        
        }

        &-divider {
          display: none;
          
          @media(min-width: $screen-tablet-min) {
            display: initial;
            border-left: 1px solid $primary-color;
            height: 20px;
            margin-left: 12px;
            margin-right: 12px;
            width: 0;
            padding: 0;
          }
        }

        button {
          color: $primary-color;
        }
      }
    }
  }
}