@import '../components/core.scss';

$tdWidth: 80px;

.table-batch {
  &.app-responsive-table {
    @media(max-width: $screen-tablet-max) {
      thead th {
        font-size: 13px;
        padding: $space-1;
      }
      
      tr {
        font-size: 11px;
        padding: $space-1;
      }
    }
  }

  @media(max-width: $screen-phone-max) {
    &.responsiveTable td.pivoted {
      padding-left: $tdWidth;
    
      .tdBefore {
        padding: $space-1;
        width: calc(#{$tdWidth} - #{$space-1});
      }
    }
  }
}