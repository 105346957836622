@import '../components/core.scss';

.app-main-container.MuiContainer-root {
  @media(max-width: $screen-phone-max) {
    padding-left: 0;
    padding-right: 0;
  }
}

.MuiContainer-root.MuiContainer-disableGutters.MuiContainer-maxWidthLg {
  margin-top: 0.4em !important;
}

.app-main-container {
  margin-top: 1.6em;
  background-color: white;
  border-radius: 20px;
  margin-bottom: 3em;
  padding-top: $space-3;

  &-top {    
    padding: 0 0 $space-2;
    
    h3 {
      font-size: 1em;
      font-weight: 700;
      color: #666;
      padding: 0 $space-2;

      @media(min-width: $screen-tablet-min) {
        padding: $space-1 0;
        font-size: 1.4em;
      }
    }
  }

  &-content {
    max-width: 100%;
    margin: 0 0 $space-8;
  }

  .search {
    &-field {
      width: 100%;
      background-color: #fff;
      margin: 0 0 24px;
      
      @media(max-width: $screen-phone-max) {
        width: calc(100% - 32px);
        margin: 0 16px 24px;
      }
    }

  }

  .MuiOutlinedInput-root {
    width: 100%;
  }

  .MuiButton-root {
    // width: 100%;

    @media(max-width: $screen-phone-max) {
      min-width: initial!important;
    }
  }

  .search-with-filter {
    margin-top: 2em;
    margin-bottom: 2em;
    
    @media(max-width: $screen-phone-max) {
      padding: 0 16px;
    }
  }
}