@import '../components/core.scss';

@media(max-width: $screen-phone-max) {
  .page-protocols {
    .responsiveTable td.pivoted {  
      padding-left: 78px;
    
      .tdBefore {
        padding: $space-1;
        width: calc(78px - #{$space-1});
      }
    }

    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
      margin-bottom: $space-1;
    }
  }
}
