@import '../components/core.scss';

.grid-dg-final {
  padding: 0 0 $space-2;

  $tdWidth: 78px;

  .table-dg-final {
    &.app-responsive-table {
      @media (max-width: $screen-tablet-max) {
        thead th {
          font-size: 13px;
          padding: $space-1;
        }

        tr {
          font-size: 11px;
          padding: $space-1;
        }
      }
    }

    @media (max-width: $screen-phone-max) {
      &.responsiveTable td.pivoted {
        padding-left: $tdWidth;

        .tdBefore {
          padding: $space-1;
          width: calc(#{$tdWidth} - #{$space-1});
        }
      }
    }
  }

  hr {
    width: 100%;
    display: flex;
    height: 0;
    border: 0 solid #0000001f;
    border-top-width: 1px;
  }

  h2 {
    font-size: 20px;
    margin-bottom: $space-3;

    &.title-bigger {
      @media (min-wifdth: $screen-tablet-min) {
        font-size: 24px;
      }
    }
  }

  .header-info {
    &-left {
      margin-bottom: $space-3;
      display: flex !important;
      flex-direction: row;
    }

    &-right {
      display: flex !important;
      flex-direction: column;

      @media (min-width: $screen-tablet-min) {
        align-items: flex-end;
      }
    }
  }

  ul {
    margin: $space-1 0;

    li {
      line-height: $space-4;
      font-weight: 700;
    }
  }

  .grid-item {
    margin-bottom: $space-1;
  }
}
