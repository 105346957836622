@import '../components/core.scss';

.grid-batch-form {
  .grid-item {
    // margin-top: $space-2;
    margin-bottom: $space-2;

    .small-date {
      position: relative;
      top: 4px;
    }

    h2 {
      font-size: 16px;
      margin-top: $space-2;
    }

    .MuiFormLabel-root {
      @media(max-width: $screen-phone-max) {
        font-size: 12px;
      }
    }
  }
}