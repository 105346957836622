@import '../components/core.scss';

$tdWidth: 106px;

.table-farm {
  &.app-responsive-table {
    @media (min-width: $screen-tablet-min) and (max-width: $screen-tablet-max) {
      thead th {
        font-size: 13px;
        padding: $space-1;
      }

      tbody td {
        font-size: 11px;
        padding: $space-1;

        button {
          font-size: 10px;
          margin: 0 2px !important;
          padding: 0 !important;
        }
      }
    }
  }

  @media (max-width: $screen-phone-max) {
    &.responsiveTable td.pivoted {
      padding-left: $tdWidth;

      .tdBefore {
        padding: $space-1;
        width: calc(#{$tdWidth} - #{$space-1});
      }
    }
    .td-btn-arr button {
      font-size: 0.95em;
      margin: 1px !important;
    }
  }
}
