@import '../components/core.scss';

  $tdWidth: 64px;

  .table-dn {
    &.app-responsive-table {
      td {
        @media(min-width: $screen-tablet-min) {
          padding: $space-2 $space-1;
        }
      }

      thead th {
        &:nth-child(1) {
          width: 100%;
        @media(min-width: $screen-tablet-min) {
            width: 50%;
          }
          @media(min-width: $screen-desktop-min) {
            width: 35%;
          }
        }
        &:nth-child(2) {
          width: 100%;
        }
      }

      @media(max-width: $screen-tablet-max) {
        thead th {
          font-size: 13px;
          padding: $space-1;
        }
        
        tr {
          font-size: 11px;
          padding: $space-1;
        }
      }

      .td-btn {
        vertical-align: middle;

        button {
          line-height: 30px;
          min-width: 120px!important;
        }
      }
    }

    @media(max-width: $screen-phone-max) {
      &.responsiveTable td.pivoted {
        padding-left: $tdWidth;
      
        .tdBefore {
          padding: $space-1;
          width: calc(#{$tdWidth} - #{$space-1});
        }
      }
    }
  }

.grid-dn {
  padding: 0 0 $space-2;

  @media(min-width: $screen-tablet-min) {
    padding-top: $space-3;
  }

  hr {
    width: 100%;
    display: flex;
    height: 0;
    border: 0 solid #0000001f;
    border-top-width: 1px;
  }

  h2 {
    font-size: 20px;
    margin-bottom: $space-3;
    
    &.title-bigger {      
      @media(min-wifdth: $screen-tablet-min) {
        font-size: 24px;
      }
    }
  }

  .header-info {
    &-left {
      margin-bottom: $space-3;
    }

    &-right {
      display: flex!important;
      flex-direction: column;
      
      @media(min-width: $screen-tablet-min) {
        align-items: flex-end;
      }
    }
  }

  ul {
    margin: $space-1 0;

    li {
      line-height: $space-4;
    }
  }

  .grid-item {
    margin-bottom: $space-1;
  }
}