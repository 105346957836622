@import '../components/core.scss';

.app-wrapper-bg {
  color: #666;
  background-color: #fff;
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 28px;
  background-size: 100% 380px;
  min-height: 100vh;
  
  // @media(max-width: $screen-tablet-min) {
  //   background-size: 100% 430px;
  // }
}