@keyframes appearTable {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.mobile-table-card-title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border: 1px solid #ea5d0d;
  box-shadow: 4px 6px 15px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  transition: ease all 0.2s;

  &:hover{
    background: #f8f8f8;
  }

  h2 {
    font-size: 1rem;
    font-weight: 600;
    color: #ea5d0d;
  }

  svg{
    color: #ea5d0d;
  }
}

.table-container{
  overflow-x: scroll;
  animation: appearTable 0.5s ease-out;
}