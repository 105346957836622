$totalColumns: 12;
$frame: 100% / $totalColumns;

.grid {
  width: inherit;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  
  $sizes: p 0 $screen-phone-max, t $screen-tablet-min $screen-tablet-max,
    d $screen-desktop-min ($screen-desktop-min + 5000);

  @each $size in $sizes {
    $device: nth($size, 1);
    $min: nth($size, 2);
    $max: nth($size, 3);

    // Pega a partir do breakpoint especificado
    @media (min-width: #{$min}) {
      @for $i from 0 through $totalColumns {
        .#{$device}-#{$i} {
          width: $frame * $i;
          display: block;
          box-sizing: border-box;      
        }
      }
    }

    // O offset deve pegar apenas dentro do breakpoint especificado
    @media (min-width: #{$min}) and (max-width: #{$max}) {
      @for $i from 0 through $totalColumns {
        .#{$device}-offset-#{$i} {
          margin-left: $frame * $i;
          display: block;
          box-sizing: border-box;      
        }
      }
    }
  }
}
