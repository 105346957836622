@import '../components/core.scss';

$tdWidth: 64px;

.table-d0-resync {
  &.app-responsive-table {
    thead th {
      min-width: 280px;
    }
    
    button {
      max-width: 100%;
      min-width: 50px;
    }

    @media(max-width: $screen-tablet-max) {
      thead th {
        font-size: 13px;
        padding: $space-1;
      }
      
      tr {
        font-size: 11px;
        padding: $space-1;
      }
    }

    .confirm-btn-wrapper {
      align-items: flex-end;

      button {
        max-width: 100%;
        width: 102px;
        height: 48px;
        
        @media(max-width: $screen-phone-max) {
          width: 100%;
        }
      }
    } 
  }

  @media(max-width: $screen-phone-max) {
    &.responsiveTable td.pivoted {
      padding-left: $tdWidth;
    
      .tdBefore {
        padding: $space-1;
        width: calc(#{$tdWidth} - #{$space-1});
      }
    }
  }
}  

.d0-resync-grid-form {
  padding: $space-1;
  
  @media(min-width: $screen-tablet-min) {
    padding: $space-4 $space-2 $space-2;
  }

  &-top-info {
    &-left {
      @media(max-width: $screen-phone-max) {        
        display: flex!important;
        flex-direction: column;
      }

      li {
        line-height: $space-4;
        
        strong {
          margin-top: $space-2;
          display: block;
    
          @media(min-width: $screen-tablet-min) {
            display: initial;        
          } 
        }
      }
    }

    &-right {
      flex-direction: column;
      margin-top: 24px; 
      justify-content: space-between;       
      
      span {
        font-size: .8em;
      }
      
      @media(min-width: $screen-tablet-min) {
        margin-top: 0;
        align-items: flex-end;
        font-size: 1em;
      }
    }
  }

  .grid-item {
    padding-right: 3px;
    margin-bottom: $space-2;

    hr {
      border: 0 solid #0000001f;
      border-top-width: 1px;
    }

    h2 {
      font-size: 20px;
      margin-bottom: $space-2;
    }

    h3 {
      font-size: 18px;
      margin-bottom: $space-1;
      
      @media(min-width: $screen-tablet-min) {
        font-size: 24px;
      }
    }

    &.p-offset-3 {
      @media(max-width: $screen-tablet-max) {
        margin-left: 0;
      }
    }

    .MuiButton-root {
      @media(max-width: $screen-phone-max) {
        min-width: initial!important;
      }
    }
  }
}