$properties: 'margin', 'padding';
$directions: 'top', 'right', 'bottom', 'left';

$space-1: 8px;
$space-2: $space-1 * 2;
$space-3: $space-1 * 3;
$space-4: $space-1 * 4;
$space-5: $space-1 * 5;
$space-6: $space-1 * 6;
$space-7: $space-1 * 7;
$space-8: $space-1 * 8;
$space-9: $space-1 * 9;
$space-10: $space-1 * 10;

/* A classe p é uma abreviação para "property" */
.p { 
  &-hide {
    &-phone {
      @media(max-width: $screen-phone-max) {
        display: none !important;
      }
    }

    &-tablet {
      @media(min-width: $screen-tablet-min) and (max-width: $screen-tablet-max) {
        display: none !important;
      }
    }

    &-desktop {
      @media(min-width: $screen-desktop-min) {
        display: none !important;
      }
    }
  }

  &-full-width {
    width: 100% !important;
  }

  &-color {
    &-primary {
      color: $primary-color!important;
    }
  }
  
  &-bg-color {
    &-primary {
      background-color: $primary-color!important;
    }
  }

  &-display {
    &-flex {
      display: flex !important;
    }

    &-block {
      display: block !important;
    }
  }

  &-flex-direction {
    &-column {
      flex-direction: column !important;
    }
  }

  &-list-style-none {
    list-style: none !important;
  }

  &-cursor {
    &-pointer {
      cursor: pointer !important;
    }
  }
  
  &-align-items {
    &-center {
      align-items: center!important;
    }
    &-flex-end {
      align-items: flex-end!important;
    }
  }

  &-font {
    &-weight {
      &-700 {
        font-weight: 700!important;
      }
    }
  }

  &-justify {
    &-flex-end {
      justify-content: flex-end!important;
    }
    
    &-center {
      justify-content: center!important;
    }
    
    &-space-between {
      justify-content: space-between!important;
    }
  }

  &-text {
    &-center {
      text-align: center !important;
    }

    &-left {
      text-align: left !important;
    }

    &-right {
      text-align: right !important;
    }

    &-uppercase {
      text-transform: uppercase !important;
    }

    &-underline {
      text-decoration: underline !important;
    }
  }
  &-position {
    &-relative {
      position: relative!important;
    }
  }

  @for $i from 0 through 6 {
    &-flex-#{$i} {
      flex: #{$i} !important;
    }
  }

  @for $i from 0 through 10 {
    &-line-height-#{$i} {
      line-height: #{$i * $space-1} !important;
    }

    @each $property in $properties {
      &-#{$property}-#{$i} {
        #{$property}: #{$i * $space-1} !important;
      }

      &-#{$property}-horizontal-auto {
        #{$property}-left: auto !important;
        #{$property}-right: auto !important;
      }

      @each $direction in $directions {
        &-#{$property}-#{$direction}-#{$i} {
          #{$property}-#{$direction}: #{$i * $space-1} !important;
        }
      }

      &-#{$property}-horizontal-#{$i} {
        #{$property}-left: #{$i * $space-1} !important;
        #{$property}-right: #{$i * $space-1} !important;
      }

      &-#{$property}-vertical-#{$i} {
        #{$property}-top: #{$i * $space-1} !important;
        #{$property}-bottom: #{$i * $space-1} !important;
      }
    }
  }
}
