.btn {
  background: $secondary;
  color: white;
  font-weight: 500;
  font-size: 1.25em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid darken($secondary, 5%);
  cursor: pointer;
  transition: background-color 300ms;
  text-decoration: none;
  &-primary {
    border: unset;
    &.active {
      background-color: $primary;
    }
    :hover {
      background-color: $primary;
    }
  }
}
