@import '../components/core.scss';

$tdWidth: 84px;

.table-d0 {
  &.app-responsive-table {
    @media(max-width: $screen-tablet-max) {
      thead th {
        font-size: 13px;
        padding: $space-1;
      }
      
      tr {
        font-size: 11px;
        padding: $space-1;
      }
    }
  }

  @media(max-width: $screen-phone-max) {
    &.responsiveTable td.pivoted {
      padding-left: $tdWidth;
    
      .tdBefore {
        padding: $space-1;
        width: calc(#{$tdWidth} - #{$space-1});
      }
    }
  }
}  

.d0-grid-form {
  padding: $space-1;
  
  @media(min-width: $screen-tablet-min) {
    // padding: $space-4 $space-2 $space-2;
  }

  &-top-info {
    &-left {
      @media(max-width: $screen-phone-max) {        
        // align-items: center;
        display: flex!important;
        flex-direction: column;
      }

      li {
        line-height: $space-4;
        
        strong {
          margin-top: $space-2;
          display: block;
    
          @media(min-width: $screen-tablet-min) {
            display: initial;        
          } 
        }
      }
    }

    &-right {
      flex-direction: column;
      margin-top: 24px; 
      justify-content: space-between;       
      
      span {
        font-size: .8em;
      }
      
      @media(min-width: $screen-tablet-min) {
        margin-top: 0;
        align-items: flex-end;
        font-size: 1em;
      }
    }
  }

  .grid-item {
    padding-right: 3px;
    margin-bottom: $space-2;

    hr {
      border: 0 solid #0000001f;
      border-top-width: 1px;
    }

    h3 {
      font-size: 20px;
      margin-bottom: $space-1;
    }

    &.p-offset-3 {
      @media(max-width: $screen-tablet-max) {
        margin-left: 0;
      }
    }

    .MuiButton-root {
      @media(max-width: $screen-phone-max) {
        min-width: initial!important;
      }
    }
  }
}