.sidebar {
  color: #1c1d1f;
  height: 100%;
  width: 100%;
}

.sidebar-item {
  font-size: 1.2em;
  width: 100%;
  border: 1px solid#ccc;
  border-left: none;
  border-right: none;

  &:not(:last-child) {
    border-bottom: none;
  }
  height: 2.6em;
  padding-left: 5px;
  padding-top: 4px;

  &:hover {
    background: $secondary;
    // color: #fff;
    cursor: pointer;

    .play-icon {
      color: #1c1d1f;
    }

    .sidebar-link {
      font-weight: 500;
    }
  }
}

.sidebar-link {
  padding-left: 15px;
  display: block;
  width: 100%;
  height: 100%;
}

.play-icon {
  position: relative;
  top: 5px;
  color: #ccc;
}

.title {
  font-size: 2.3em;
  padding-left: 5px;
  padding-bottom: 5px;
  color: #1c1d1f;
  border-bottom: 1px solid #ccc;
}

.bold {
  color: #1c1d1f;
  font-weight: 700;
}

.description {
  font-size: 1em;
  margin-top: 10px;
  padding-right: 30px;
  padding-left: 10px;
  text-align: justify;
}
