@import '../components/core.scss';

$tdWidth: 90px;

@media(max-width: $screen-phone-max) {
  .table-bulls {
    &.responsiveTable td.pivoted {  
      padding-left: $tdWidth;
    
      .tdBefore {
        padding: $space-1;
        width: calc(#{$tdWidth} - #{$space-1});
      }
    }
  }
}