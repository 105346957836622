@import '../components/core.scss';

$tdWidth: 100px;

.protocol-form-grid {
  padding: 0 $space-2;

  div {
    padding-right: 4px;
    margin-bottom: $space-1;
  }

  @media(min-width: $screen-tablet-min) {
    padding: 0;
  }
}
@media(max-width: $screen-phone-max) {
  .table-protocol-form {
    &.responsiveTable td.pivoted {  
      padding-left: $tdWidth;
    
      .tdBefore {
        padding: $space-1;
        width: calc(#{$tdWidth} - #{$space-1});
      }
    }
  }
}