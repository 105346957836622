@import '../components/core.scss';

.chart {
  &-item {
    max-width: 100%;
    overflow: auto;

    @media(min-width: $screen-tablet-min) {
      margin: 0 $space-5 $space-10 $space-5;
    }
  }
}