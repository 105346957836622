@import '../components/core.scss';

@media(max-width: $screen-phone-max) {
  .page-users {
    .responsiveTable td.pivoted {  
      padding-left: 110px;
    
      .tdBefore {
        padding: $space-1;
        width: calc(110px - #{$space-1});
      }
    }
  }
}
