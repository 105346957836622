.page-login {
  background-color: rgba(234, 234, 234, 0.27);
  justify-content: center;

  &-form {
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    background-position-x: -5vw;
  }
}
